import skill from "@/http/requests/skill";

export default {
  fetchSkills({ commit }) {
    return new Promise((resolve, reject) => {
      skill
        .fetchSkills()
        .then((response) => {
          commit("SET_SKILLS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchSkill({ commit }, id) {
    return new Promise((resolve, reject) => {
      skill.fetchSkill(id)
        .then((response) => {
          commit("SET_SKILL", response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchMcq({ commit }, id) {
    return new Promise((resolve, reject) => {
      skill.fetchMcq(id)
        .then((response) => {
          commit("SET_MCQ", response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchAssessment({ commit }, id) {
    return new Promise((resolve, reject) => {
      skill.fetchAssessment(id)
        .then((response) => {
          commit("SET_ASSESSMENT", response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchTags({ commit }) {
    return new Promise((resolve, reject) => {
      skill
        .fetchTags()
        .then((response) => {
          commit("SET_TAGS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  initializeAssessment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      skill.initializeAssessment(payload)
        .then((response) => {
          commit("SET_MCQ", response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  submitTest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      skill.submitTest(payload)
        .then((response) => {
          commit("SET_ASSESSMENT", response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  initializeTestAssessment({ commit }, id) {
    return new Promise((resolve, reject) => {
      skill.initializeTestAssessment(id)
        .then((response) => {
          commit("SET_ASSESSMENT", response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  startTest({ commit }, id) {
    return new Promise((resolve, reject) => {
      skill.startTest(id)
        .then((response) => {
          commit("SET_ASSESSMENT", response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  nextQuestion({ commit }, payload) {
    return new Promise((resolve, reject) => {
      skill.nextQuestion(payload)
        .then((response) => {
          commit("SET_MCQ", response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchMcqScores({ commit }, id) {
    return new Promise((resolve, reject) => {
      skill.fetchMcqScores(id)
        .then((response) => {
          commit('SET_MCQ_SCORES', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
};
