import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/",
    component: () => import("./layouts/LayoutAuth"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("./views/Auth/Login"),
        meta: {
          rule: "public",
        },
      },
      {
        name: "forgot-password",
        path: "/forgot-password",
        component: () => import("./views/Auth/ForgotPassword"),
        meta: {
          rule: "public",
        },
      },
      {
        name: "reset-password",
        path: "/reset-password",
        component: () => import("./views/Auth/ResetPassword"),
        meta: {
          rule: "public",
        },
      },
    ],
  },
  {
    path: "",
    component: () => import("./layouts/LayoutMain"),
    children: [
      {
        path: "dashboard",
        component: () => import("./views/Dashboard"),
        meta: {
          title: "Dashboard",
          description: "Overview of your daily activities",
          rule: "private",
        },
      },
      {
        name: "skills",
        path: "skills",
        component: () => import("./views/Skills"),
        meta: {
          title: "Skills",
          rule: "private",
        },
      },
      {
        name: "skill-test",
        path: "skills/:id/test",
        component: () => import("./views/Skills/Test"),
        meta: {
          title: "Skill Test",
          description: "Take home assignment testing your knowledge in the selected domain.",
          rule: "private",
        },
      },
      {
        name: "skill-mcq",
        path: "skills/:id/mcq",
        component: () => import("./views/Skills/Mcq"),
        meta: {
          title: "MCQ Quiz",
          description: "Questions testing your knowledge in the selected domain.",
          rule: "private",
        },
      },
      {
        name: "assessment-details",
        path: "skills/:id/assessment-details",
        component: () => import("./views/Skills/AssessmentDetails"),
        meta: {
          title: "Skill Assessment",
          description: "Overview of your assessment",
          rule: "private",
        },
      },
      {
        name: "timesheet",
        path: "timesheet",
        component: () => import("./views/Timesheet"),
        meta: {
          title: "Timesheet",
          description: "Overview of your daily time clock",
          rule: "private",
        },
      },
      {
        path: "settings",
        component: () => import("./views/Settings"),
        meta: {
          title: "Settings",
          description: "Download Report of activities",
          rule: "private",
        },
      },
      {
        name: "billing",
        path: "billing",
        component: () => import("./views/Billing"),
        meta: {
          title: "Billing",
          description: "Overview of users requests",
          rule: "private",
        },
      },
      {
        name: "billing-overview",
        path: "billing/:id",
        component: () => import("./views/Billing/Overview"),
        meta: {
          title: "Billing",
          description: "Overview of users billing",
          rule: "private",
        },
      },
      {
        path: "stand-up",
        component: () => import("./views/StandUp"),
        meta: {
          title: "Stand Up",
          description: "Overview of your daily activities",
          rule: "private",
        },
      },
    ],
  },
  {
    name: 'error-page',
    path: '*',
    component: () => import("./views/Errors")
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.rule !== "public" && !store.getters["auth/isUserLoggedIn"]) {
    next("/login");
  } else if (to.name === "login" && store.getters["auth/isUserLoggeedIn"]) {
    next("/dashboard");
  } else next();
});

export default router;
